// Here you can add other styles
.c-app:not(.c-legacy-theme):not(.c-dark-theme)
  .c-sidebar.c-sidebar-lg-show.c-sidebar-fixed {
  box-shadow: none;
}

html:not([dir='rtl']) .c-sidebar:not(.c-sidebar-right) {
  margin-left: -230px;
}

.c-sidebar {
  background: #392f2c;
  width: 230px;
  flex: 1 1 23px;

  .c-sidebar-brand {
    justify-content: flex-start;
    padding-left: 50px;
  }

  .c-sidebar-brand {
    background: white;
  }

  .c-sidebar-nav {
    border-top: 1px solid #d8dbe0;
    margin-top: -1px;
  }

  .c-sidebar-nav-link.c-active {
    background: #14193b80;
  }

  .c-sidebar-minimizer .c-d-md-down-none {
    background: #14193b;
  }

  .c-sidebar-title {
    background-color: #3e66b580;
    padding: 16px 18px 10px;
  }
}

.c-wrapper {
  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    margin-left: 230px !important;
  }
}

.header-components {
  position: sticky;
  top: 0;
  z-index: 5;
}

.c-app:not(.c-legacy-theme):not(.c-dark-theme) .c-header.c-header-fixed {
  box-shadow: none;
  border-bottom: 1px solid #c8ced3;
}

.breadcrumb {
  padding: 0.8445rem 1rem;
  margin: 0;
  border: none;
  position: sticky;
  top: 57px;
  z-index: 3;
  box-shadow: 0 2px 2px 0 rgba(60, 75, 100, 0.14),
    0 3px 1px -2px rgba(60, 75, 100, 0.12), 0 1px 5px 0 rgba(60, 75, 100, 0.2);
}

.c-main {
  padding-top: 2.5rem;
}

.container-fluid {
  padding-bottom: 4rem;

  @media screen and (min-width: map-get($container-max-widths, lg)) {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
}

.page-title {
  font-size: 24px;
  margin-bottom: 2.125rem;
  color: $black;
}

.table {
  min-height: 200px;

  tbody {
    background-color: white;
  }

  thead,
  tbody {
    th,
    td {
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      border-bottom: 1px solid #d8dbe0;
      vertical-align: middle;

      &.p-0 {
        border-bottom: 1px solid transparent;

        .collapse {
          border-bottom: 1px solid #d8dbe0;
        }
      }

      &:first-child {
        border-left: 1px solid #d8dbe0;
      }

      &:last-child {
        border-right: 1px solid #d8dbe0;
      }
    }
  }

  thead {
    th {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }

    .table-sm th {
      padding-left: 4px !important;
    }
  }

  .p-0 {
    height: 0;
  }
}

.c-sidebar > li:first-child {
  transition: all 0.4s;
  background: #3e66b5;
}

.c-sidebar.c-sidebar-unfoldable > li:first-child {
  opacity: 0.2;
  padding-left: 56px;
}

.c-sidebar.c-sidebar-unfoldable:hover > li:first-child {
  opacity: 1;
  padding-left: 0;
}

select {
  option:disabled {
    color: #5d697473;
  }
}

.input-group-append,
.input-group-preppend {
  button {
    min-width: auto;
  }
}

input[type='file'] {
  height: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-file-label::after {
  left: 0;
  border-radius: 0.25rem;
  border: none;
}

.dash-border {
  border-top: 1px dashed #d5d7d8;
}

.documents-box-container {
  .btn {
    min-width: auto;
  }
}

.line-through {
  text-decoration: line-through;
}

.text-black {
  color: #000 !important;
}

.custom-table {
  th {
    padding: 0.75rem !important;
    padding-left: 1.5rem !important;

    svg {
      left: 0;
    }
  }
}

.badge-dark {
  background-color: #b3b3b3;
}

.nowrap {
  white-space: nowrap;
}

.white-space-normal {
  white-space: normal;
}

.collapse .card {
  border-radius: 0;
}

.aside-title {
  padding: 0.8445rem 1rem;
  opacity: 0.8;
}

.dot {
  flex-basis: 10px;
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.flex-fill {
  flex: 1 !important;
}

.v-align-bottom {
  vertical-align: bottom;
}

.flex-basis-auto {
  flex-basis: auto !important;
}

.c-switch.matrix.modified {
  .c-switch-slider {
    background-color: #f2f2f2;
  }
}

.number::-webkit-outer-spin-button,
.number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.number[type='number'] {
  -moz-appearance: textfield;
}

.border-with-title {
  text-align: center;
  position: relative;

  span {
    position: absolute;
    top: -12px;
    background-color: white;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
  }
}

.gap-1 {
  gap: 4px;
}

.gap-2 {
  gap: 8px;
}

.gap-3 {
  gap: 16px;
}

.gap-4 {
  gap: 24px;
}

.gap-5 {
  gap: 48px;
}

.acceptanceList {
  .c-main {
    padding-top: 0 !important;
  }

  .form {
    padding-top: 100px !important;
  }

  .tableTitle {
    background-color: #f8f8f8;
    position: fixed;
    z-index: 1;
    height: 100px;
    transform: translateY(-100%);
    width: 90%;
    width: -moz-available;
    margin-right: 15px;
    overflow-x: auto;

    @media screen and (min-width: map-get($container-max-widths, lg)) {
      margin-right: 3.125rem;
    }
  }
}

.badge-warning {
  color: white !important;
}
