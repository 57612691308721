.scoringContainer {
  background-image: linear-gradient(to right, #19ba41, #168131);
  color: white;
  min-height: 135px;
}
.scoringTitle {
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 5px;
}
.scoringSubtitle {
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 6px;
}
.scoringDescription {
  font-size: 9px;
  line-height: 12px;
  font-weight: 200;
  margin-top: 10px;
}

.progressbar {
  background-color: #aaaaaa;
}

.statusTitle {
  font-size: 22px;
}

.statusButton {
  min-width: 135px;
  box-shadow: none;
  flex: 1;
}

.scoringTitle {
  font-size: 19px;
  line-height: 25px;
  font-weight: 400;
}
.productBoxContainer table td {
  border: none !important;
  border-bottom: 1px solid #d8dbe0 !important;
}
.productBoxContainer table th {
  border: none !important;
  border-bottom: 1px solid #d8dbe0 !important;
}
.inputContainer {
  width: 100px;
  margin-left: auto;
}
.progressValue {
  width: 110px;
}
.progressValueLabel {
  min-width: 40px;
}
.archive_button {
  margin-left: 20px;
}
