.header {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  margin: 0 20px;
}

h1 {
  font-size: 44px;
  font-weight: bold;
}

h2 {
  font-size: 22px;
  margin-bottom: 11px;
}
