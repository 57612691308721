.inputContainer {
  width: 100px;
  margin-left: auto;
}

p {
  margin-top: 2em;
  font-size: 15px;
}
.table th,
.table td {
  border: none !important;
  border-bottom: 1px solid #d8dbe0 !important;
}
.table tr:last-child td {
  border: none !important;
}
