.wrapper {
  position: relative;
}

.search {
  padding-right: calc(30px + 0.75rem) !important;
}

.iconWrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-left: 1px solid #e4e7ea;
  color: #a3a6a8;
  cursor: pointer;
}
