// Variable overrides
$sidebar-width: 200px;
$body-bg: #f8f8f8;
$body-color: #000;

$theme-colors: (
  white: #fff,
  black: #000,

  primary: #2a4de3,
  secondary: #ff6c0c,
  light-secondary: #fee7da,
  danger: #d10032,
  success: #19ba41,

  custom-color: #900,
  grey: #d5d7d8,
  light-grey: #f4f4f4,
  dark-grey: #464646,
  grey2: #5c6873,
);
