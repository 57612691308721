.loaderContainer {
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
  left: 0;
}

.td {
  width: calc(100% / 14);
  text-align: center;
  padding: 5px;
}

.tdName {
  min-width: 200px;
}
.tdGray {
  background: #f4f4f4;
  padding: 0 10px;
}

.w-100 {
  width: 100%;
}

.table {
  min-width: 950px;
  width: 100%;
}
.numberInput::-webkit-outer-spin-button,
.numberInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.numberInput {
  appearance: none;
  -moz-appearance: textfield;
  padding-left: 4px;
  text-align: right;
  padding-right: 2rem;
}

.prc {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  right: 12px;
  top: 0;
}
