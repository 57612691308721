.container {
  max-width: 1200px;
  margin: auto;
  min-height: calc(100vh - 278px);
}

.card {
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  margin: 0 20px;
}

.wrapper {
  max-width: 400px;
  margin: auto;
}

h3 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}

.CheckCircleIcon {
  color: #00b8d2;
  width: 100px;
  height: 100px;
  display: block;
  margin: auto;
  margin-bottom: 30px;
}
